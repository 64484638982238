import React, { useEffect } from "react";
import "../css/Address.css";
import location from "../assets/icons/location.png";
import "aos/dist/aos.css";
import AOS from "aos";

const Address = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div
      className="Address-container"
      data-aos="fade-up"
      data-aos-duration="3000"
    >
      <div className="head">
        <img src={location} alt="location in kaarunyabharathi" />
        Available Location's
      </div>
      <div className="Address">
        <a href="https://maps.app.goo.gl/qpWhUYRtfQVyWhJT7" target="_black">
          <div className="list">
            <p>Kaarunya Bharathi Arts, Rotary club,old mathigiri.</p>
          </div>
        </a>

        <a href="https://maps.app.goo.gl/pMvs93NxjiWr1Gh57" target="_black">
          <div className="list">
            <p>
              Amazing kids play school, Manikavasagar Street,Mgr nagar,
              mookandapalli.
            </p>
          </div>
        </a>

        <a href="https://maps.app.goo.gl/CJrU1cLKZzLerGcq9" target="_black">
          <div className="list">
            <p>Blossom matriculation school, MM Nagar, Lal</p>
          </div>
        </a>

        <a href="https://maps.app.goo.gl/rMDZQBP2Rr5FpQnZ9" target="_black">
          <div className="list">
            <p>Rama yoga center, SM Nagar</p>
          </div>
        </a>

        <a href="https://maps.app.goo.gl/T3q9F41Kyg6bEFmG9" target="_black">
          <div className="list">
            <p>Tiny tots play Home, Samadhanapuram, Dharga</p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Address;
