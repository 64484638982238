import React from 'react'
import Nav from '../componet/Nav'
import Footer from '../componet/Footer'
import ContantInfo from '../componet/ContactInfo'
// import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <>
       {/* <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-51Q1NWGQKD"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-51Q1NWGQKD');
          `}
        </script>
      </Helmet> */}
      {/* ---------------------- */}
    <Nav/>
    <ContantInfo/>
    <Footer/>
    </>
  )
}

export default Contact