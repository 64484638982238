import React, { useEffect } from 'react'
import  '../css/TeachingMethods.css'
import 'aos/dist/aos.css';
import AOS from 'aos';

// img
 import Online_Offline from '../assets/icons/online.png'
 import basictoadvance from '../assets/icons/basic-to-advance.png'
 import USA from '../assets/icons/usa.png'

const TeachingMethods = () => {
 
    useEffect(() => {
        AOS.init();
      }, []);
    

    return (
    <div className='TeachingMethods-container'>
        <div className='head'>
            <h1>Class Availability</h1>
        </div>
        <div className='TeachingMethods'>
       
        <div className='list'data-aos="fade-right">
                <div className='img'>
                    <img src={basictoadvance} alt='basictoadvance'/>
                </div>
                <div className='class'>
                    <h1>Teaching from Basic to Advanced</h1>
                </div>
        </div>
        <div className='list'  data-aos="fade-down">
                <div className='img'>
                    <img src={Online_Offline} alt='Online_Offline'/>
                </div>
                <div className='class'>
                    <h1>Offline and Online classes </h1>
                </div>
        </div>

        <div className='list' data-aos="fade-left">
                <div className='img'>
                    <img src={USA} alt='US Teaching Children'/>
                </div>
                <div className='class'>
                    <h1>National and International classes</h1>
                </div>
        </div>

        </div>
    </div>
  )
}

export default TeachingMethods