import React from 'react'
import '../css/feedback_box.css'

const FeedbackBox = () => {
  return (
    <div className='FeedbackBox-container'>
        <div className='FeedbackBox'>
            <div className='heading'>
                <h1>Tell Us What You Think</h1>
            </div>
            <div className='message'>
                <a href='https://g.page/r/CTbekky5IgmwEAE/review' target='_black'>
                    <input type='text' readOnly placeholder='Write your feedback here...'/>
                </a>
            </div>
        </div>
    </div>
  )
}

export default FeedbackBox