
import React from 'react';
import Nav from '../componet/Nav'
import Footer from '../componet/Footer'
import '../css/Workshop.css'; // Import your CSS file for styling
// import { Helmet } from 'react-helmet';


const Gallery = () => {
    const Gallery = [
        { id: 1, img: "", name: "Processing" },
    ];
    

  return (
    <>
       {/* <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-51Q1NWGQKD"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-51Q1NWGQKD');
          `}
        </script>
      </Helmet> */}
      {/* ---------------------- */}
    <Nav/>
            {/* -------------------------------- */}
            <div className='Workshop-container'>
                <div className='workshop'>
                    <div className='heading'>
                        <h1>Gallery</h1>
                    </div>
                    <div className='course-container'>
                        {Gallery.map((data) => (
                            <div className='List' key={data.id}> {/* Added a key prop */}
                                <div className='img'>
                                    <img src={data.img} alt={data.name} /> {/* Use data.img and data.name */}
                                </div>
                                <div className='name'>
                                    <h1>{data.name}</h1> {/* Use data.name */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* -------------------------------- */}
            <Footer />
    </>
  )
}
export default Gallery

