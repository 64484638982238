import React from 'react';
import Nav from '../componet/Nav'
import Footer from '../componet/Footer'
import Painting_Brush_Img from '../assets/other_img/painting_Brush.jpg'
import '../css/Workshop.css'; // Import your CSS file for styling
import { useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet';

// thum
import children_img from '../assets/course_workshop/Children_Art_Course.jpg'
import Thanjavur_painting_img from '../assets/course_workshop/Thanjavur_painting.jpg'
import Fine_art_course_img from '../assets/course_workshop/Fine_art_course.jpg'
import Warli_art_img from '../assets/course_workshop/Warli_art.jpg'
import Watercolor_img from '../assets/course_workshop/Watercolor.jpg'
import Drawing_art_and_craft_img from '../assets/course_workshop/Drawing_art_and_craft.jpg'
import Freehand_Lower_img from '../assets/course_workshop/Freehand_Lower.jpg'
import Still_life_drawings_img from '../assets/course_workshop/Still_life_drawings.jpg'

const Course = () => {
    const navicaton=useNavigate()
    const Course = [
        {
            id: 1,
            img:Fine_art_course_img,
            name: "Diploma in Fine Arts(DFA)"
        },
        {
            id: 2,
            img:children_img,
            name: "Children Art Course (CAC)"
        },
        {
            id: 3,
            img:Thanjavur_painting_img,
            name: "Diploma Tanjore Paintings (DTP)"
        },
        {
            id: 4,
            img:Freehand_Lower_img,
            name: "Free hand drawing lower & higher grade"
        },
        {
            id: 5,
            img:Still_life_drawings_img,
            name: "Still life drawing & Painting"
        },
        {
            id: 6,
            img:Drawing_art_and_craft_img,
            name: "Kids Drawing, Painting, and Arts & Craft"
        },
        {
            id: 7,
            img:Watercolor_img,
            name: "Watercolor Painting Course"
        },
       
    ];
// -------------------------------
const handleReDir =(()=>{
    navicaton('/contact')
})
    return (
        <>
           {/* <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-51Q1NWGQKD"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-51Q1NWGQKD');
          `}
        </script>
      </Helmet> */}
      {/* ---------------------- */}
            <Nav />
            {/* -------------------------------- */}
            <div className='Workshop-container'>
                <div className='workshop'>
                    <div className='heading'>
                        <h1>Available Course</h1>
                    </div>
                    <div className='course-container'>
                        {Course.map((data) => (
                            <div className='List' key={data.id} onClick={handleReDir}> {/* Added a key prop */}
                                <div className='img'>
                                    <img src={data.img} alt={data.name} /> {/* Use data.img and data.name */}
                                </div>
                                <div className='name'>
                                    <h1>{data.name}</h1> {/* Use data.name */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* -------------------------------- */}
            <Footer />
        </>
    );
};

export default Course;
