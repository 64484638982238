import React from 'react'
import { Link } from 'react-router-dom'
import whatsapp_icon from '../assets/icons/whatsapp.png'
import telephone_icon from '../assets/icons/telephone-call.png'
import '../css/Contact_btn.css'

const Contact_btn = () => {
  return (
    <div className='Contact-btn-container'>
        <div className='Contact-btn'>
            <div className='items'>
                <div className='list'>
                    <Link to={'https://wa.me/+919994281962'}>
                    <img src={whatsapp_icon} alt=''/>
                    </Link>
                </div>
                <div className='list'>
                    <Link to={'tel:+91 99942 81962'}>
                    <img src={telephone_icon} alt=''/>
                    </Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Contact_btn