import React from 'react'
import Nav from '../componet/Nav'
import Footer from '../componet/Footer'
import Slider from '../componet/Slider'
import WhyUseUs from '../componet/WhyUseUs'
import IntutionCount from '../componet/IntutionCount'
import TeachingMethods from '../componet/TeachingMethods'
import Address from '../componet/Address'
import Feedback from '../componet/Feedback'
import Contact_btn from '../componet/Contact_btn'
import FeedbackBox from '../componet/FeedbackBox'
// import { Helmet } from 'react-helmet';


const Index = () => {
  return (
    <div>
     {/* <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-51Q1NWGQKD"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-51Q1NWGQKD');
          `}
        </script>
      </Helmet> */}
      {/* ----------------------
      */}
     
      <Nav/>
      <Slider/>
      <TeachingMethods/>
      <WhyUseUs/>
      <IntutionCount/>
      <Address/>
      <Feedback/>
      <FeedbackBox/>
      <Footer/>
      <Contact_btn/>
    </div>
  )
}

export default Index