import React from 'react';
import Nav from '../componet/Nav'
import Footer from '../componet/Footer'
import '../css/Workshop.css'; // Import your CSS file for styling
 
// import img
import Painting_Brush_Img from '../assets/other_img/painting_Brush.jpg'
import img_1 from '../assets/work_shop/stone.webp'
import img_2 from '../assets/work_shop/caffe painting.jpg'
import img_3 from '../assets/work_shop/glass painting.jpg'
import img_4 from '../assets/other_img/water_painting.jpg'
import img_5 from '../assets/work_shop/Acrylic painting.jpg'
import { useNavigate } from 'react-router-dom';
// import { Helmet } from 'react-helmet';

// thum
import Clay_modelling_img from '../assets/course_workshop/Clay_modelling.jpg'
import Origami_img from '../assets/course_workshop/Origami.jpg'
import Pencil_shading_img from '../assets/course_workshop/Pencil_shading.jpg'
import Madhubani_art_img from '../assets/course_workshop/Madhubani_art.jpg'
import Mandala_art_img from '../assets/course_workshop/Mandala_art.jpg'
import Warli_art_img from '../assets/course_workshop/Warli_art.jpg'

const Workshop = () => {
    const navicaton=useNavigate()
    const workshops = [
        {
            id: 1,
            img:    img_1,
            name: "Stone painting"
        },
        {
            id: 2,
            img: img_2,
            name: "Coffee painting"
        },
        {
            id: 3,
            img: img_3,
           name: "Glass painting"
        },
        {
            id: 4,
            img:img_4,
            name: "Watercolor Painting"
        },
        {
            id: 5,
            img:img_5,
            name: "Acrylic painting"
        },
        {
            id: 6,
            img: Origami_img,
            name: "Origami"
        },
        {
            id: 7,
            img: Clay_modelling_img,
            name: "Clay modeling"
        },
        {
            id: 8,
            img: Pencil_shading_img,
            name: "Pencil Shading"
        },
        {
            id: 9,
            img:Mandala_art_img,
            name: "Mandala Art"
        },
        {
            id: 10,
            img:Madhubani_art_img,
            name: "Madhubani Art"
        },
        {
            id: 11,
            img:Warli_art_img,
            name: "Warli Art"
        },
    ];
const handleReDir =(()=>{
    navicaton('/contact')
})
    return (
        <>
           {/* <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-51Q1NWGQKD"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-51Q1NWGQKD');
          `}
        </script>
      </Helmet> */}
      {/* ---------------------- */}
            <Nav />
    {/* -------------------------------- */}

            <div className='Workshop-container'>
                <div className='workshop'>
                    <div className='heading'>
                        <h1>Work Shop</h1>
                    </div>
                    <div className='course-container'>
                        {workshops.map((data) => (
                            <div className='List' onClick={handleReDir} key={data.id}> {/* Added a key prop */}
                                <div className='img'>
                                    <img src={data.img} alt={data.name} /> {/* Use data.img and data.name */}
                                </div>
                                <div className='name'>
                                    <h1>{data.name}</h1> {/* Use data.name */}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* -------------------------------- */}
            <Footer />
        </>
    );
};

export default Workshop;
