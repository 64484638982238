// src/components/NotFound.js
import React from 'react';
import '../css/error.css'; // Import the CSS file
// import { Helmet } from 'react-helmet';

const Error = () => {
    return (
        <>
        {/* <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-51Q1NWGQKD"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-51Q1NWGQKD');
          `}
        </script>
      </Helmet> */}
      {/* ---------------------- */}
        <div className="not-found">
            <h1>404</h1>
            <p>Oops! The page you are looking for does not exist.</p>
            <a href="/">Go back to Home</a>
        </div>
        </>
    );

};

export default Error;
