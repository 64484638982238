import React from "react";
import "../css/contact.css";
import { Link } from "react-router-dom";

// social media icons
import instagram_icon from "../assets/Social_media/instagram.svg";
import facebook_icon from "../assets/Social_media/facebook.svg";

const ContactInfo = () => {
  return (
    <>
      <main>
        <div class="contact_page">
          <h1>Contact Us</h1>
        </div>
        <div class="contact-container">
          <div class="address">
            {/* <!-- Address --> */}
            <div class="items">
              <Link
                to={"https://maps.app.goo.gl/EzzZGKgNTvtZPooD8"}
                target="_black"
              >
                <div class="icon">
                  <i class="bi bi-geo-alt-fill"></i>
                </div>
                <div class="links">
                  <div class="head">
                    <h1>Address</h1>
                  </div>
                  <div class="main_link">
                    <p>Plot no: 3, Rotary club, mathigiri kootu road, hosur.</p>
                  </div>
                </div>
              </Link>
            </div>
            {/* <!-- contact number --> */}
            <div class="items">
              <div class="icon">
                <i class="bi bi-telephone-fill"></i>
              </div>
              <div class="links">
                <div class="head">
                  <h1>Phone No.</h1>
                </div>
                <div class="main_link">
                  <Link to={"tel:+91 99942 81962"}>
                    <p>+91 99942 81962</p>
                  </Link>
                  <Link to={"tel:+91 90251 57220"}>
                    <p>+91 90251 57220</p>
                  </Link>
                </div>
              </div>
            </div>
            {/* <!-- gmail id --> */}
            <div class="items">
              <Link
                to={"mailto:vmkaarunyabharathiarts@gmail.com"}
                target="_black"
              >
                <div class="icon">
                  <i class="bi bi-envelope-fill"></i>
                </div>
                <div class="links">
                  <div class="head">
                    <h1>Write to us</h1>
                  </div>
                  <div class="main_link">
                    <p>vmkaarunyabharathiarts@gmail.com</p>
                  </div>
                </div>
              </Link>
            </div>
            {/* <!-- social media link --> */}
            <div class="items">
              <div class="icon">
                <i class="bi bi-link-45deg"></i>
              </div>
              <div class="links">
                <div class="head">
                  <h1>Social Links</h1>
                </div>
                <div class="main_link">
                  <div className="social-media">
                    <Link to={"https://www.instagram.com/kaarunyabharathiarts/?igshid=YmMyMTA2M2Y%3D"} target="_black">
                      <img src={instagram_icon} alt="instagram" />
                    </Link>
                  </div>
                  <div className="social-media">
                    <Link to={"https://www.facebook.com/p/kaarunya-Bharathi-Arts-100089575601379/"} target="_black">
                      <img src={facebook_icon} alt="facebook" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="map">
          <div class="view">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3892.1399741780606!2d77.80697647454265!3d12.70428572068374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae715a606d0723%3A0xb00922b94c92de36!2sKaarunya%20bharathi%20arts!5e0!3m2!1sen!2sin!4v1721960320248!5m2!1sen!2sin"
              width="100%"
              height="300px"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Google Maps"
            ></iframe>
          </div>
        </div>
      </main>
    </>
  );
};

export default ContactInfo;
