import React, { useState, useEffect } from "react";
import "../css/Feedback.css";
import star_fill from "../assets/icons/start-fill.svg";
import star_off_fill from "../assets/icons/start-off-fill.svg";
import star_border from "../assets/icons/start-border.svg";
//Img
import user_Img from "../assets/icons/user.png";
const Feedback = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const feedBackInfo = [
    {
      img: user_Img,
      name: "Gokul J",
      occupation: "",
      message:
        "The drawing class at Kaarunyabharathi was excellent, with knowledgeable instructors and a supportive environment. Highly recommended for all skill levels.",
      rate: 5,
      googleReviewLink:'https://www.google.com/maps/reviews/data=!4m8!14m7!1m6!2m5!1sChZDSUhNMG9nS0VJQ0FnSURyOE96ZUJnEAE!2m1!1s0x0:0xb00922b94c92de36!3m1!1s2@1:CIHM0ogKEICAgIDr8OzeBg%7CCgwI35nJtAYQ6J-axQE%7C?hl=en-GB&kgs=11a41a6b45c064f4'
    },
    {
      img: user_Img,
      name: "sivaprabha v",
      occupation: "",
      message:
        "My hearty congratulations to both the drawing teachers of my daughter M.Saikeerthana..Really wonderful efforts taken by all the students.. All the drawings were really nice and superb.. I wish you all the very best to move forward with this spirit.. Thank you Venkatesh anna and Manju... 😍🙏🙏",
      rate: 5,
      googleReviewLink:'https://g.co/kgs/UNRrBdf'
    },
    {
      img: user_Img,
      name: "Anand C",
      occupation: "",
      message: `It's very good and nice to learn drawing from them. They both teaches us as like as their children it's a good platform to learn drawing. We glad to had they both.
Deeksha......😍♥️
Bhagyashree...💞💐`,
      rate: 5,
      googleReviewLink:'https://g.co/kgs/BzEq6cG'
    },
    {
      img: user_Img,
      name: "ramya naveen",
      occupation: "",
      message:
        "Congratulations of kaarunya Bharathi arts. I'm N.Yashikha & N.Nithusree parent. In first experience of art it's very nice. My heartful tq for sir & madam",
      rate: 5,
      googleReviewLink:'https://g.co/kgs/zRmXfkR'
    },
    {
      img: user_Img,
      name: "vinoth kumar tv",
      occupation: "",
      message:
        "Good thought to encourage young talent and we'll organised function. I'm stunned with your commitment and you have a bright future. Keep it up.",
      rate: 5,
      googleReviewLink:'https://g.co/kgs/hg3hWhx'
    },
    {
      img: user_Img,
      name: "Poornima R",
      occupation: "",
      message: "This is good opportunity for all children  🎨🖼️",
      rate: 5,
      googleReviewLink:'https://g.co/kgs/BMzRyzh'
    },
    {
      img: user_Img,
      name: "Sumanthu Paitipal",
      occupation: "",
      message: "best place for drawing traning",
      rate: 5,
      googleReviewLink:'https://g.co/kgs/9Fv6WJm'
    },
    {
      img: user_Img,
      name: "venkat e",
      occupation: "",
      message: "It is very good institute",
      rate: 5,
      googleReviewLink:'https://g.co/kgs/mKSAhi8'
    },
    {
      img: user_Img,
      name: "Mohankumar Muniraj",
      occupation: "",
      message: "Wow super",
      rate: 5,
      googleReviewLink:'https://g.co/kgs/wvCwEJg'
    },
    {
      img: user_Img,
      name: "Sanjay V",
      occupation: "",
      message: "Best experience",
      rate: 5,
      googleReviewLink:'https://g.co/kgs/jCakJHF'
    },
    {
      img: user_Img,
      name: "Thiru Mani",
      occupation: "",
      message: "Super response thank you kaarunya Bharathi arts",
      rate: 5,
      googleReviewLink:'https://g.co/kgs/rgDcv4y'
    },
  ];

  // Function to get the star rating
  const getStarRating = (rating) => {
    const stars = [];
    for (let i = 0; i < 6; i++) {
      if (i < Math.floor(rating)) {
        stars.push(<img key={i} src={star_fill} alt="" />);
      } else if (i === Math.floor(rating) && rating % 1 !== 0) {
        stars.push(<img key={i} src={star_off_fill} alt="" />);
      } else {
        stars.push(<img key={i} src={star_border} alt="" />);
      }
    }
    return stars.slice(0, 5);
  };

  // Effect to change the current index every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % feedBackInfo.length);
    }, 2000); // Change every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [feedBackInfo.length]);

  return (
    <div className="trustedourclient-container">
      <div className="trustedour">
        <div className="left">
          <h1>FeedBacks</h1>
        </div>
        <div className="right">
          {feedBackInfo.map((info, index) => (
            <div
              className={`list ${index === currentIndex ? "active" : ""}`}
              key={index}
              style={{ display: index === currentIndex ? "block" : "none" }}
            >
              <div className="profile-name">
                <div className="user-name">
                  <div className="left">
                    <div className="img">
                      <img src={info.img} alt={info.name} />
                    </div>
                  </div>
                  <div className="right">
                    <div className="user_name">{info.name}</div>
                    <div className="occupation">{info.occupation}</div>
                  </div>
                </div>
                <div className="rate">{getStarRating(info.rate)}</div>
              </div>
              <a href={info.googleReviewLink} target="_blank">
              <div className="message">
                <p>{info.message}</p>
              </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feedback;
